<template>
  <div>

    <!--<div class="feedback">-->
        <!--<div class="feedback_in" style="display:none">-->
            <!--<img src="../assets/images/ico_success.svg" alt="">-->
            <!--<span>提交成功</span>-->
            <!--<label>您已成功上传影像，我行将于1小时内完成影像审核，审核结果以短信通知，如有疑问可联系我行客服4008308003</label>-->
            <!--<button class="back">知道了</button>-->
        <!--</div>-->
        <!--<div class="feedback_in">-->
            <!--<img src="../assets/images/ico_success.svg" alt="">-->
            <!--<span>提交成功</span>-->
            <!--<label>您已成功提交申请资料，我行将于 1~3 个工作日内完成开通审核，审核结果以短信通知为准，短信发送号码 <font>95508</font>。如有疑问可进入一天膳事平台在线客服咨询。</label>-->
        <!--</div>-->
        <!--<div class="feedback_qr"><img src="../assets/images/qr.png" alt=""></div>-->
        <!--<div class="feedback_in" style="display:none">-->
            <!--<img src="../assets/images/ico_fail.svg" alt="">-->
            <!--<span>开通失败</span>-->
            <!--<label>失败原因展示</label>-->
            <!--<button class="block">常见问题指引</button>-->
            <!--<button class="block">开通教学视频</button>-->
            <!--<button class="back">返回</button>-->
        <!--</div>-->
    <!--</div>-->

  </div>
</template>


<style scoped>
</style>

<script>
export default {
  data() {
    return {

    };
  },
  methods: {

  }

};
</script>
